import userService from "../../services/userService";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const Version = ({ }) => {
  const location = useLocation();

  const handleLogout = () => {
    userService.logout();
  }

  // const handleEmail = () => {
  //   console.log("CLICKED");
  //   let emailTo = "joneill@sembracare.com";
  //   let emailSub = "SembraApplicant Portal Request";
  //   location.href = "mailto:"+emailTo+'?subject='+emailSub;
  // }
  return (
    <div>
      <span
        style={{
          fontSize: "1.25rem",
          marginRight: "30px"
        }}
      >
        v1.1.13 - STAGING
      </span>
      <Button style={{marginRight:"30px"}} onClick={handleLogout}>Logout</Button>
      <Button href="mailto:jamie@sembracare.com?subject=SembraApplicant Portal Request">Contact</Button>
    </div>
  );
}

export default Version