import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import userService from "../../services/userService";
import { Button, Form } from "react-bootstrap";
import {
  Modal,
  ModalTitle,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";
import moment from "moment/moment";
import signatureService from "../../services/signatureService";
import SignatureCanvas from "react-signature-canvas";

export default function SubmitApplication({
  user,
  userData,
  updatePage,
  shake,
}) {
  const navigate = useNavigate();
  const [termsAccepted, setTermsAccepted] = useState({
    cbEverify: false,
    cbBackground: false,
    cbDocuments: false,
    cbContinuingEd: false,
    cbRegulatory: false,
    cbInformationCollection: false,
    cbEqualOpp: false,
    cbRepresentations: false,
    cbDisputes: false,
    cbSignature: false
  });
  const [signatureReady, setSignatureReady] = useState(false);
  const [signatureID, setSignatureID] = useState(false);
  // const [checkSignature, setCheckSignature] = useState(false);
  const [submitReady, setSubmitReady] = useState(false);
  const [validated, setValidated] = useState(false);
  const date = moment().format();
  let sigPad = {};
  const [signatureError, setSignatureError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    // if(userData.termsAccepted)
    // console.log(userData);
  }, []);

  const onModalHide = () => {
    setModalShow(false);
  };

  const handleSubmit = async () => {
    userData.applicantStatus = "complete";
    if (!userData.password) {
      await userService.saveUserData(userData.username, "applicantStatus", "complete");
      await userService.saveUserData(userData.username, "password", "pending");
      await userService.saveUserData(userData.username, "signatureID", signatureID);
      await userService.saveUserData(userData.username, "termsAccepted", true);
      await userService.saveUserData(userData.username, "submissionDate", date);
      userData.password = "pending";
      userData.signatureID = signatureID;
      userData.termsAccepted = true;
      userData.submissionDate = date;
    }

    updatePage();
    setValidated(true);
  };

  const handleValidate = (cb) => {
    const updatedObject = termsAccepted;
    updatedObject[cb] = !updatedObject[cb];
    if (updatedObject.cbBackground && updatedObject.cbDisputes && updatedObject.cbEqualOpp && updatedObject.cbEverify
      && updatedObject.cbInformationCollection && updatedObject.cbRegulatory && updatedObject.cbRepresentations
      && updatedObject.cbSignature && updatedObject.cbContinuingEd && updatedObject.cbDocuments)
      setSignatureReady(true)
    else
      setSignatureReady(false);

    setTermsAccepted(updatedObject);
  }

  const clearCanvas = () => {
    sigPad.clear();
    setSignatureError(false);
  };

  const handleSig = async () => {
    if (!sigPad.isEmpty()) {
      try {
        let signatureObject = {
          signatureUser: userData.username,
          signatureUserName: userData.empInfo.firstName + " " + userData.empInfo.lastName,
          signatureTitle: "Employee Application Terms and Conditions",
          signatureCompany: userData.agencyInfo.name,
          signature: sigPad.getTrimmedCanvas().toDataURL("image/png")
        }
        let { data: signature } = await signatureService.createSignature(signatureObject);
        let signatureIDResponse = signature._id;
        setSignatureID(signatureIDResponse);
        console.log(signature);
        setSubmitReady(true);
        setSignatureError(false);
      } catch {
        setSignatureError(true);
        setErrorMessage("Error Saving Signature - Please Refresh and Try Again");
      }
    } else {
      setSignatureError(true);
      setErrorMessage("Please Provide Signature");
    }
  }

  // const handleSMS = async () => {
  //   let signatureObject = {
  //     signatureUser: userData.username,
  //     signatureUserName: userData.empInfo.firstName + " " + userData.empInfo.lastName,
  //     signatureTitle: "Employee Application Terms and Conditions",
  //     signatureCompany: userData.agencyInfo.name
  //   }
  //   let { data: signature } = await signatureService.createSignature(signatureObject);
  //   // console.log(signature);

  //   let signatureIDResponse = signature._id;
  //   setSignatureID(signatureIDResponse);

  //   let newMessage = {
  //     messageTo: "+1" + userData.empInfo.phoneNumber.replace(/\D/g, ''),
  //     messageText: "Application Link To Sign Terms and Conditions: https://sign.sembratest.com/docSign/" + signatureIDResponse,
  //     messageUser: userData.username,
  //     messageFrom: "Employee Application"
  //   }
  //   // console.log(newMessage)

  //   let { data: SMSReturn } = await signatureService.sendSignatureText(newMessage);
  //   setSignatureReady(false);
  //   setCheckSignature(true);
  // }

  // const handleSignatureVerify = async () => {
  //   const { data: signatureVerification } = await signatureService.verifySignature(signatureID);
  //   // console.log(signatureVerification);
  //   if (signatureVerification === "Verified") {
  //     setCheckSignature(false);
  //     setSubmitReady(true);
  //   } else
  //     shake()
  // }

  return (
    <>
      <Modal show={modalShow} centered>
        {" "}
        <ModalHeader as="span" className="alert alert-warning">
          <ModalTitle as="h4">Incomplete Tasks!</ModalTitle>
        </ModalHeader>
        <ModalBody as="section">
          <p>You have unfinished tasks!</p>
          <p>
            To submit application, it is mandatory to complete all tasks in both
            phase 1 and phase 2. Please click "Confirm" to return to the
            application and ensure the completion of all tasks highlighted in
            red in Phase 1 and Phase 2.
          </p>
        </ModalBody>
        <ModalFooter as="footer">
          <Button variant="outline-primary" onClick={onModalHide}>
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
      {userData.applicantStatus === "complete" || userData.applicantStatus === "newDoc" || userData.applicantStatus === "synced" ? (
        <section className="main-section">
          <h2>Submit Success</h2>
          <section className="main-section-content text-start">
            <p>
              <strong>Congratulations! </strong>
            </p>

            <p>Your application was successfully submitted!</p>

            <p> Our office will be in contact with you soon.</p>

            <p><b>Your IVRID for Prerequisites Training is {userData.password}</b></p>
            {userData.password === "pending" && <p style={{ color: 'red' }}><b>Your IVRID will take up to 5 minutes to update on this page</b></p>}
            <p><b>Your Password for Training is the last 4 digits of your Social Security Number</b></p>

            <p>
              If you want to get started on the new hire requirements, feel free
              to continue to Phase 4.
            </p>
            <p>
              *** Note: Phase 4 is not required prior to offer. Phase 4 is for
              applicants who have been offered a position pending background
              check ***
            </p>
            <div className="d-flex flex-column">
              <Link className="btn btn-primary mb-4" to="../phase4-upload-documents">
                Upload Documents
              </Link>
              <Button
                className="btn btn-primary"
                onClick={() => window.open(
                  'https://' + userData.agencyInfo.agencyCode + 'training.sembra1.com',
                  '_blank'
                )}
              >
                Prerequisites
              </Button>
            </div>
            {/* <div className="btn-bar text-end">
              <Button
                onClick={() => userService.logout()}
                variant="primary"
              >
                Logout
              </Button>
            </div> */}
          </section>
        </section>
      ) : (
        <section>
          <section className="main-section">
            <h2>Submit Application</h2>
            {/* Page Instructions  */}
            <section className="main-section-content text-start">
              <p>
                <strong>
                  Congratulations! You have provided all required information!
                </strong>{" "}
              </p>
              <p>
                Review and accept terms in the "Terms and Conditions" section
              </p>
              <p>After terms are accepted click "Submit Application"</p>
              <div className="alert alert-info">
                <p>Requirements: Review and accept Terms. Submit Application</p>
                <p className="mb-0">
                  You must click "Submit Application" in the "Terms and
                  Conditions" section for your application to be submitted.
                </p>
              </div>
            </section>
          </section>

          {/* Terms and Conditions  */}
          <section className="main-section">
            <h2>Terms and Conditions</h2>
            <div className="main-section-content">
              <section className="text-start">
                <p>I agree to the following (you must read and check all):</p>
                <Form validated={validated} noValidate>
                  <Form.Check
                    className="mb-3"
                    type="checkbox"
                    id="cbEverify"
                    label="I must comply with North Carolina and Federal law on work verification to be hired by the Agency. I authorize the Agency to verify my employment eligibility through the use of the E-Verify system or any other legal means."
                    name="cbEverify"
                    onChange={() => handleValidate("cbEverify")}
                    required
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                  />
                  <Form.Check
                    className="mb-3"
                    type="checkbox"
                    id="cbBackground"
                    label="I must comply with North Carolina and Federal law on criminal background checks to be hired by the Agency. I authorize the Agency to conduct a criminal background search on me of criminal records maintained by the state and the Federal governments, and release the Agency from any liability arising from the use of such information."
                    name="cbBackground"
                    onChange={() => handleValidate("cbBackground")}
                    required
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                  />
                  <Form.Check
                    className="mb-3"
                    type="checkbox"
                    id="cbDocuments"
                    label="I must provide the Agency with all documents and information requested to verify my ability to work for the Agency."
                    name="cbDocuments"
                    onChange={() => handleValidate("cbDocuments")}
                    required
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                  />
                  <Form.Check
                    className="mb-3"
                    type="checkbox"
                    id="cbContinuingEd"
                    label="I must provide the Agency with any requested evidence of compliance with continuing education requirements at any time."
                    name="cbContinuingEd"
                    onChange={() => handleValidate("cbContinuingEd")}
                    required
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                  />
                  <Form.Check
                    className="mb-3"
                    type="checkbox"
                    id="cbRegulatory"
                    label="I must tell the Agency of any action taken against me by any regulatory body."
                    name="cbRegulatory"
                    onChange={() => handleValidate("cbRegulatory")}
                    required
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                  />
                  <Form.Check
                    className="mb-3"
                    type="checkbox"
                    id="cbInformationCollection"
                    label="By using this Application Portal, I consent to the collection, use, and disclosure of my personal information to various third-parties for the purposes of compliance with law, conducting background checks, verifying my credentials, and other business purposes."
                    name="cbInformationCollection"
                    onChange={() => handleValidate("cbInformationCollection")}
                    required
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                  />
                  <Form.Check
                    className="mb-3"
                    type="checkbox"
                    id="cbEqualOpp"
                    label="The Agency has advised me it is an equal opportunity employer and considers all applicants for employment without regard to race, color, religion, sex, national origin, age, disability, or any other legally protected status."
                    name="cbEqualOpp"
                    onChange={() => handleValidate("cbEqualOpp")}
                    required
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                  />
                  <Form.Check
                    className="mb-3"
                    type="checkbox"
                    id="cbRepresentations"
                    label="I have not relied on any statements, representations or warranties, whether written or oral, other than what is in these terms and conditions."
                    name="cbRepresentations"
                    onChange={() => handleValidate("cbRepresentations")}
                    required
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                  />
                  <Form.Check
                    className="mb-3"
                    type="checkbox"
                    id="cbDisputes"
                    label="Disputes arising from the submission of a job application to the Agency are subject to the laws of North Carolina, without regard to its conflicts of law principles. Any such dispute shall be first submitted to mediation using the rules for mediation in the North Carolina Superior Courts."
                    name="cbDisputes"
                    onChange={() => handleValidate("cbDisputes")}
                    required
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                  />
                  <Form.Check
                    className="mb-3"
                    type="checkbox"
                    id="cbSignature"
                    label="I agree to receive a SMS Text Message to complete my application.  My electronic signature is adopted by me as legally valid and attests that all information provided with my job application is true and accurate."
                    name="cbSignature"
                    onChange={() => handleValidate("cbSignature")}
                    required
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                  />
                  <section className="text-center">
                    {signatureReady && !submitReady ? (
                      <div>
                        <p className="alert alert-success text-start">
                          Terms accepted. Sign below to submit your application
                        </p>
                        <div>
                          <SignatureCanvas
                            ref={(ref) => {
                              sigPad = ref;
                            }}
                            penColor="#0000c2"
                            backgroundColor="white"
                            canvasProps={{ className: "sigCanvas" }}
                          />
                        </div>
                        {signatureError && (
                          <p className="alert alert-danger">{errorMessage}</p>
                        )}
                        <div className="btn-bar">
                          <button className="btn btn-primary" type="button" onClick={handleSig}>
                            Save
                          </button>
                          <button className="btn btn-danger" type="button" onClick={clearCanvas}>
                            Clear
                          </button>
                        </div>
                        {/* <p className="alert alert-success text-start">
                          Terms accepted. Click the button below to submit your
                          application
                        </p>
                        <Button variant="primary" type="button" onClick={handleSMS}>
                          Send SMS Text for Signature
                        </Button> */}
                      </div>
                    ) : !submitReady && (
                      <p className="alert alert-danger text-start">
                        Please Accept All Terms And Conditions Above
                      </p>
                    )}
                  </section>
                  {/* {checkSignature && (
                    <section className="text-center mt-3">
                      <Button variant="primary" type="button" onClick={handleSignatureVerify}>
                        Click Here Once You Have Completed Signature Link Sent To Phone
                      </Button>
                      <p className="alert alert-danger text-start mt-2">
                        Signature Not Yet Verified
                      </p>
                    </section>
                  )} */}
                  {submitReady && (
                    <section className="text-center mt-3">
                      <p className="alert alert-success text-center">
                        Terms accepted. Click the button below to submit your
                        application
                      </p>
                      <Button variant="primary" type="button" onClick={handleSubmit}>
                        Submit Application
                      </Button>
                    </section>
                  )}
                </Form>
              </section>
            </div>
          </section>
        </section>
      )}
    </>
  );
}
